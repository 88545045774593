<ng-container [ngSwitch]="state">
	<ng-container *ngSwitchCase="FormState.Loading">Loading form...</ng-container>

	<form #frm *ngSwitchCase="FormState.Loaded" class="cm-form" (ngSubmit)="onSubmitInternal()">
		<h2 class="form-title" *ngIf="formTitle" [innerHTML]="formTitle"></h2>
		<div class="form-desc mb-1" *ngIf="formIntro" [innerHTML]="formIntro"></div>

		<div *ngIf="paginated && paginationPostion === 'top'" class="btn-toolbar justify-content-center mb-3">
			<div class="btn-group" *ngIf="paginationStyle === 'number'">
				<button
					type="button"
					*ngFor="let _page of pages; index as pageI"
					class="btn {{ pageI < availablePage ? 'btn-success' : 'btn-dark' }}"
					[ngClass]="{ 'btn-active': pageI === activePage }"
					[disabled]="pageI > availablePage"
					(click)="onPageClick(pageI)"
				>
					{{ pageI + 1 }}
				</button>
			</div>
			<div *ngIf="paginationStyle === 'dots'">
				<button
					type="button"
					*ngFor="let _page of pages; index as pageI"
					class="btn {{ pageI < availablePage ? 'btn-success' : 'btn-dark' }}"
					[ngClass]="{ 'btn-active': pageI === activePage }"
					[disabled]="pageI > availablePage"
					(click)="onPageClick(pageI)"
				></button>
			</div>
		</div>

		<div *ngIf="errorMessage" class="text-danger">{{ errorMessage }}</div>

		<div *ngFor="let page of pages | slice: 0:maxPage; index as pageI" [hidden]="paginated && pageI !== activePage">
			<h2 *ngIf="page.title">{{ page.title }}</h2>
			<p *ngIf="page.intro" [innerHTML]="page.intro"></p>
			<ng-container *ngFor="let item of page.formItems">
				<ng-container *ngIf="item.isFormRow(); then formRow; else fieldset"></ng-container>

				<ng-template #formRow>
					<div class="form-row">
						<div
							*ngFor="let field of item.val.fieldsVisible()"
							class="col-12 col-lg-{{ Math.floor(12 / item.val.fields.length) }}"
							[ngClass]="{ 'mb-3': !field.field.type || field.field.type !== 'hidden' }"
						>
							<cm-field *ngIf="field.visible" [field]="field.field"></cm-field>
						</div>
					</div>
				</ng-template>

				<ng-template #fieldset>
					<cm-fieldset
						[legend]="item.val.legend"
						[additive]="item.val.additive"
						[rows]="item.val.rows"
						[entries]="item.val.entries"
						[disabled]="item.val.disabled"
					></cm-fieldset>
				</ng-template>
			</ng-container>
		</div>

		<div class="form-row" *ngIf="paginationPostion != 'bottom'">
			<div *ngIf="paginated && availablePage === 0" class="form-group col-12 text-center">
				<input type="button" [value]="startText || nextText" class="btn btn-primary" (click)="onNextClick()" />
			</div>

			<div *ngIf="availablePage > 0" class="form-group col-6 text-center">
				<button type="button" class="btn btn-primary" [disabled]="activePage === 0" (click)="onPrevClick()">
					{{ prevText }}
				</button>
			</div>

			<div *ngIf="availablePage > 0 && activePage < pages.length - 1" class="form-group col-6 text-center">
				<input type="button" [value]="nextText" class="btn btn-primary" (click)="onNextClick()" />
			</div>

			<div
				*ngIf="activePage === pages.length - 1 || !paginated"
				class="form-group text-center {{ activePage > 0 ? 'col-6' : 'col-12' }}"
			>
				<input
					type="submit"
					[class]="submitClass || 'btn btn-primary'"
					[value]="submitTextOverride || submitText"
					[disabled]="submitDisabled"
				/>
			</div>
		</div>
		<div class="form-row" *ngIf="paginationPostion === 'bottom'">
			<div *ngIf="paginated && availablePage === 0" class="form-group col-4 text-center"></div>
			<div *ngIf="availablePage > 0" class="form-group col-4 text-center">
				<button
					type="button"
					class="btn btn-secondary rounded"
					[disabled]="activePage === 0"
					(click)="onPrevClick()"
				>
					{{ prevText }}
				</button>
			</div>
			<div class="btn-toolbar justify-content-center mb-3 col-4">
				<div class="btn-group" *ngIf="paginated && paginationStyle === 'number'">
					<button
						type="button"
						*ngFor="let _page of pages; index as pageI"
						class="btn {{ pageI < availablePage ? 'btn-success' : 'btn-dark' }}"
						[ngClass]="{ 'btn-active': pageI === activePage }"
						[disabled]="pageI > availablePage"
						(click)="onPageClick(pageI)"
					>
						{{ pageI + 1 }}
					</button>
				</div>
				<div *ngIf="paginated && paginationStyle === 'dots'">
					<div
						*ngFor="let _page of pages; index as pageI"
						class="pagination-dot rounded-circle mx-1 d-inline-block pointer"
						[ngClass]="{ active: pageI === activePage }"
						(click)="pageI < availablePage ? onPageClick(pageI) : null"
					></div>
				</div>
			</div>
			<div *ngIf="paginated && availablePage === 0" class="form-group col-4 text-center">
				<input
					type="button"
					[value]="startText || nextText"
					class="btn btn-primary rounded"
					(click)="onNextClick()"
				/>
			</div>
			<div *ngIf="availablePage > 0 && activePage < pages.length - 1" class="form-group col-4 text-center">
				<input type="button" [value]="nextText" class="btn btn-primary rounded" (click)="onNextClick()" />
			</div>

			<div *ngIf="activePage === pages.length - 1 || !paginated" class="form-group col-4 text-center">
				<input
					type="submit"
					[class]="submitClass || 'btn btn-primary rounded'"
					[value]="submitTextOverride || submitText"
					[disabled]="submitDisabled"
				/>
			</div>
		</div>
	</form>

	<ng-container *ngSwitchCase="FormState.Submitted"><div [innerHTML]="formThankYou"></div></ng-container>
</ng-container>
