import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ModalModule } from "shared";
import { StevoModule } from "../stevo/stevo.module";
import { JessContactButtons } from "./components/contact/contact-buttons.component";
import { JessHomeBannerButtons } from "./components/home/banner-buttons.component";
import { JessVideoGallery } from "./components/video-gallery/video-gallery.component";

@NgModule({
	declarations: [JessHomeBannerButtons, JessContactButtons, JessVideoGallery],
	imports: [CommonModule, FontAwesomeModule, StevoModule, ModalModule, RouterModule],
	exports: [JessHomeBannerButtons, JessContactButtons, JessVideoGallery],
})
export class JessModule {}
