<div class="cm-tab-listingviews theme-bg">
	<div class="row">
		<div
			class="h3 col-12 text-white font-normal font-weight-normal text-uppercase flex-center p-3 mb-0 bg-secondary"
		>
			Views By Listing
		</div>
		<div class="col-12">
			<div class="py-2">
				<ngb-progressbar type="primary" [value]="100" [striped]="true" [animated]="true" *ngIf="loading"
					>Loading...</ngb-progressbar
				>
			</div>
			<table
				class="table table-sm table-striped table-hover table-responsive w-100 d-block d-md-table"
				*ngIf="listings$ | async as listings"
			>
				<thead>
					<tr>
						<th>Listing</th>
						<th class="text-center">Total Viewers</th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let listing of listings">
						<td>
							<a [href]="listing.url" target="_blank">{{ listing.title }}</a>
						</td>
						<td class="text-center">
							{{ listing.count }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</div>
