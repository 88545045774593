import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges } from "@angular/core";
import { FieldOption } from "@core/app/form/field";
import { iter } from "../../../common/iter";

let idNum: number = 0;

@Component({
	selector: "cm-field-checkbox",
	templateUrl: "./field-checkbox.component.html",
	styles: [":host { display: block; }"],
})
export class FieldCheckboxComponent implements OnChanges {
	@Input() name!: string;
	@Input() options: FieldOption[] | null = null;
	@Input() disabled: boolean = false;
	@Input() label?: string;
	@Input() intro?: string;
	@Input() value: string = "";

	@Output() valueChange = new EventEmitter<string>();

	id: number = idNum++;
	valueSplit: Set<string> = new Set();

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.value !== undefined) {
			if (changes.value.currentValue === null) {
				this.valueSplit = new Set();
			} else {
				this.valueSplit = new Set(changes.value.currentValue.split(",").filter((x: string) => x !== ""));
			}
		}
		if (changes.options !== undefined) {
			this.options = changes.options.currentValue;
			this.valueSplit = this.value ? new Set(this.value.split(",").filter((x: string) => x !== "")): new Set();
		}
	}

	onCheckClicked(value: string, checked: boolean) {
		if (checked) {
			this.valueSplit.add(value);
		} else {
			this.valueSplit.delete(value);
		}

		this.value = iter(this.valueSplit)
			.toArray()
			.join(",");
		this.valueChange.emit(this.value);
	}
}
