<ng-container>
	<cm-label *ngIf="label" [forid]="id" [text]="label" [required]="required"></cm-label>
	<small *ngIf="displayFormat" class="text-muted ml-1">({{ format }})</small>
	<div *ngIf="intro" [innerHTML]="intro"></div>
	<div class="input-group">
		<input
			#input
			type="text"
			class="form-control"
			[id]="id"
			[name]="name"
			[disabled]="disabled"
			[required]="required"
			[(ngModel)]="value"
			(ngModelChange)="valueChange.emit($event)"
			(blur)="validate()"
			[attr.pattern]="pattern"
		/>
		<div class="input-group-append">
			<button
				type="button"
				class="btn btn-outline-secondary btn-sm"
				[disabled]="disabled"
				(click)="onPickerToggle($event)"
				[ngClass]="{ active: pickerVisible }"
			>
				<fa-icon [icon]="faCalendarAlt"></fa-icon>
			</button>
		</div>
	</div>
	<div *ngIf="pickerVisible" class="position-relative">
		<div class="position-absolute bg-light border shadow z-3" (click)="$event.stopPropagation()">
			<ngb-datepicker
				[(ngModel)]="ngbDate"
				[minDate]="minDate"
				(select)="onDateSelect()"
				[firstDayOfWeek]="7"
			></ngb-datepicker>
		</div>
	</div>
	<div class="invalid-feedback">{{ error }}</div>
</ng-container>
