import { Component, Input } from "@angular/core";
import { faCamera } from "@fortawesome/pro-solid-svg-icons";
import { IHomeTile } from "@model/home-tile";

@Component({
	selector: "cm-stevo-listing-card",
	template: `
		<a
			[routerLink]="data.content_page_url"
			class="d-block text-tertiary text-decoration-none rounded2 shadow overflow-hidden mw-100 mb-3"
			style="width: 325px"
		>
			<div class="px-3 py-2 bg-tertiary text-white text-uppercase text-center">
				{{ data.address1 }}<br />
				{{ data.city }}, {{ data.abbrev }} {{ data.postal_code }}
			</div>
			<div class="py-2 px-3 bg-secondary d-flex justify-content-between text-white">
				<div>{{ data.header }}</div>
				<div>{{ data.property_type }}</div>
			</div>
			<div class="p-3 bg-white">
				<div class="embed-responsive embed-responsive-4by3 rounded2 shadow">
					<img
						*ngIf="data.img_dir && data.img_file"
						class="embed-responsive-item"
						loading="lazy"
						[src]="data.img_dir + data.img_file | image: 'm'"
						alt="{{ data.address1 }} {{ data.city }}, {{ data.abbrev }} {{ data.postal_code }}"
					/>
				</div>
				<div class="mt-2 text-center text-uppercase">
					<span class="title">{{ data.beds_total }}</span> beds |
					<span class="title">{{ data.baths_total | number: "1.0-2" }}</span> baths |
					<span class="title">{{ data.square_feet }}</span> sqft
				</div>
				<div class="mt-3 d-flex justify-content-between align-items-baseline">
					<div class="text-muted">#{{ data.content_page_url.split("/").splice(-1)[0] }}</div>
					<div style="font-size: 2rem">
						{{ data.msrp | currency: undefined:undefined:"1.0-0" }}
					</div>
				</div>
			</div>
		</a>
	`,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
})
export class StevoListingCardComponent {
	@Input() data!: IHomeTile;

	showModal = false;
	showTourModal = false;

	faCamera = faCamera;
}
