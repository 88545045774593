// dependencies
import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { SharedModule } from "@core/app/shared/shared.module";
import { config } from "@fortawesome/fontawesome-svg-core";
import { NgbModal, NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { Ng2UiAuthModule, StorageType } from "ng2-ui-auth";
import { ModalModule } from "shared";
import { AccountModule } from "./account/account.module";
import { EditWidgetComponent } from "./edit-widget/edit-widget.component";
import { EqualValidatorDirective } from "./equal-validator.directive";
import { FooterStevoComponent } from "./footer/components/footer-stevo/footer-stevo.component";
import { HeaderStevoComponent } from "./header-meta/header-stevo/header-stevo.component";
import { ImageModule } from "./image/image.module";
import { InventoryModule } from "./inventory/inventory.module";
import { QuickAdminComponent } from "./quick-admin/quick-admin.component";
import { QuickBarComponent } from "./quick-bar/quick-bar.component";
import { RestService } from "./rest.service";
import { RouteResolver } from "./route-resolver.service";
import { SliderModule } from "./slider/slider.module";
import { FavoritesModule } from "./stevo/components/favorites/favorites.module";
import { StevoModule } from "./stevo/stevo.module";
import { TabCustomerViewsComponent } from "./tab-content/tab-customerviews.component";
import { TabListingViewsComponent } from "./tab-content/tab-listingviews.component";

const auth = {
	storageType: StorageType.LOCAL_STORAGE,
};
if (typeof window === "undefined") {
	auth.storageType = StorageType.MEMORY;
}

@NgModule({
	declarations: [
		EqualValidatorDirective,
		QuickBarComponent,
		QuickAdminComponent,
		TabCustomerViewsComponent,
		TabListingViewsComponent,
		HeaderStevoComponent,
		FooterStevoComponent,
		EditWidgetComponent,
	],
	imports: [
		BrowserAnimationsModule,
		BrowserModule,
		HttpClientModule,
		NgbModule,
		Ng2UiAuthModule.forRoot(auth),
		RouterModule.forChild([]),
		SharedModule,
		SliderModule,
		InventoryModule,
		ImageModule,
		AccountModule,
		ModalModule,
		FavoritesModule,
		StevoModule,
	],
	exports: [
		EqualValidatorDirective,
		NgbModule,
		QuickBarComponent,
		QuickAdminComponent,
		TabCustomerViewsComponent,
		TabListingViewsComponent,
		SharedModule,
		HeaderStevoComponent,
		SliderModule,
		InventoryModule,
		ImageModule,
		AccountModule,
		FooterStevoComponent,
		EditWidgetComponent,
	],
	providers: [RestService, NgbModal],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return { ngModule: CoreModule, providers: [RouteResolver] };
	}
	constructor() {
		config.autoAddCss = false;
	}
}
