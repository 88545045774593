import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { GTMService } from "@core/app/gtm.service";
import { ViewportService } from "@core/app/shared/services/viewport.service";
import { CompareService } from "@core/app/stevo/services/compare.service";
import { UserService } from "@core/app/user.service";
import { faEnvelope, faPhoneAlt } from "@fortawesome/pro-solid-svg-icons";
import { IPageData } from "@model/page-data";
import { map } from "rxjs/operators";

// TODO: generate this component at build time to get rid of the ngSwitch, so the unused templates can be treeshaken

@Component({
	selector: "cm-header-stevo",
	template: `
		<header id="nav-header" class="header container-fluid py-2">
			<div class="row flex-nowrap align-items-center" itemscope itemtype="http://schema.org/WPHeader">
				<div class="col-6 col-lg-auto">
					<a routerLink="/">
						<img [src]="logoUrl | image : 'm'" alt="Jess Garrsion Homes" class="img-fluid logo" />
					</a>
				</div>
				<div class="col-6 col-lg-auto flex-grow-1 text-right text-xl-left menu-content">
					<div class="d-flex flex-column text-white">
						<div class="d-none d-lg-inline">
							<fa-icon [icon]="faPhoneAlt" class="mr-2"></fa-icon>
							<a
								class="text-white"
								(click)="linkClicked('Link Click', 'Phone Call', 'click')"
								href="tel:{{ pageData.headerData.queries.GetDealerInfo[0].phone_number }}"
								>{{ pageData.headerData.queries.GetDealerInfo[0].phone_number }}</a
							>
							<span class="ml-3">
								<fa-icon [icon]="faEnvelope" class="mr-2"></fa-icon>
								<a
									href="javascript:void(0)"
									role="button"
									class="mr-3 text-white"
									(click)="showModal = true"
								>
									Send me a message!
								</a>
							</span>
						</div>
						<div class="menu">
							<cm-stevo-menu
								class="font-weight-bold"
								[appMenuid]="7"
								rootStyle="inline"
								subStyle="unstyled"
								itemscope
								itemtype="http://schema.org/SiteNavigationElement"
								(logIn)="formState = LoginState.Login; showLogin = true"
								(signUp)="formState = LoginState.Register; showLogin = true"
							></cm-stevo-menu>
						</div>
					</div>
				</div>
				<div
					class="d-none d-lg-block col-lg-auto text-right"
					*ngIf="!(userService.loggedIn$ | async) && !(mobile$ | async)"
				>
					<div
						class="btn btn-secondary text-white font-weight-bold mx-1 rounded"
						(click)="formState = LoginState.Login; showLogin = true"
					>
						SIGN IN
					</div>
					<div
						class="btn btn-primary text-white font-weight-bold mx-1 rounded"
						(click)="formState = LoginState.Register; showLogin = true"
					>
						REGISTER
					</div>
				</div>
				<div
					class="d-none d-lg-block col-lg-auto text-white text-right"
					*ngIf="(userService.loggedIn$ | async) && !(mobile$ | async)"
				>
					<a routerLink="/my-account" class="text-white tk-adelle mx-1"
						>Hello, {{ userService.firstName$ | async }}</a
					>
					&#x000B7;
					<ng-container *ngIf="(compareService.compare$ | async).size > 1"
						><a routerLink="/compare" class="text-white tk-adelle mx-1">Compare</a> &#x000B7;
					</ng-container>
					<span class="text-white tk-adelle pointer-cursor mx-1" (click)="userService.logOut()"
						>Sign Out</span
					>
				</div>
			</div>
		</header>
		<cm-send-a-message-modal [(show)]="showModal"></cm-send-a-message-modal>
		<cm-login-modal
			[state]="formState"
			[show]="showLogin"
			(loggedIn)="showLogin = false"
			(showChange)="showLogin = $event"
		></cm-login-modal>
	`,
	styleUrls: ["./header-stevo.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderStevoComponent {
	faEnvelope = faEnvelope;
	faPhoneAlt = faPhoneAlt;
	formState = LoginState.Login;
	LoginState = LoginState;
	showModal = false;
	showLogin = false;
	logoUrl =
		this.pageData.headerData.queries.GetDealerInfo[0].img_dir +
		this.pageData.headerData.queries.GetDealerInfo[0].img_file;
	mobile$ = this.viewportService.windowSize$.pipe(map((w) => w < 1250));

	constructor(
		@Inject("PAGE_DATA") public pageData: IPageData,
		public userService: UserService,
		private viewportService: ViewportService,
		public compareService: CompareService,
		private gtmService: GTMService,
	) {}

	linkClicked(category: string, label: string, action: string) {
		this.gtmService.track(category, action, label);
	}
}

enum LoginState {
	Login,
	Register,
	ForgotPass,
}
