<cm-header-stevo></cm-header-stevo>
<cm-edit-widget></cm-edit-widget>
<span itemscope [attr.itemtype]="webPageSchema">
	<div class="site-wrapper" itemscope itemtype="http://schema.org/WebPageElement" itemprop="mainContentOfPage">
		<router-outlet></router-outlet>
	</div>
</span>
<cm-footer-stevo></cm-footer-stevo>
<div toastContainer></div>
<cm-stevo-compare-popup></cm-stevo-compare-popup>
<cm-quick-bar></cm-quick-bar>
<cm-modal-outlet></cm-modal-outlet>
