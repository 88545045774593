import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Output,
	EventEmitter,
	OnChanges,
	SimpleChanges,
} from "@angular/core";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";

@Component({
	selector: "cm-login-modal",
	template: `
		<cm-modal [show]="show" (showChange)="showChange.next($event)">
			<ng-template cmModal>
				<div class="row">
					<h4
						class="h3 col-12 text-white font-normal font-weight-normal text-uppercase flex-center p-3 mb-0 bg-secondary"
					>
						Sign in & register
					</h4>
					<div class="col-lg-6 col-12 p-5 d-flex align-items-center bg-white">
						<div class="w-100">
							<cm-account (loggedIn)="loggedIn.next()" [state]="state"></cm-account>
						</div>
					</div>

					<div class="col-lg-6 col-12 p-5" style="background: #dfdfdf">
						<img
							src="https://dms.rvimg.com/s3static/22/listing-cards.jpg"
							class="rounded shadow img-fluid"
						/>
						<div class="h3 font-normal font-weight-normal my-4">Why Register?</div>
						<ul class="list-unstyled">
							<li class="mt-2 d-flex align-items-center" style="line-height: 1.3;font-size:1.35rem;">
								<fa-icon
									[icon]="faCheck"
									[fixedWidth]="true"
									class="bg-secondary text-white mr-3 rounded-circle"
								></fa-icon>
								Know when new properties that are a good fit for you land on the market
							</li>
							<li class="mt-2 d-flex align-items-center" style="line-height: 1.3;font-size:1.35rem;">
								<fa-icon
									[icon]="faCheck"
									[fixedWidth]="true"
									class="bg-secondary text-white mr-3 rounded-circle"
								></fa-icon
								>Save time shopping by saving your searches & comparisons
							</li>
							<li class="mt-2 d-flex align-items-center" style="line-height: 1.3;font-size:1.35rem;">
								<fa-icon
									[icon]="faCheck"
									[fixedWidth]="true"
									class="bg-secondary text-white mr-3 rounded-circle"
								></fa-icon>
								Get immediate alerts for any changes on properties you're interested in
							</li>
							<li class="mt-2 d-flex align-items-center" style="line-height: 1.3;font-size:1.35rem;">
								<fa-icon
									[icon]="faCheck"
									[fixedWidth]="true"
									class="bg-secondary text-white mr-3 rounded-circle"
								></fa-icon>
								Keep tabs on the west Michigan home market with market activity alerts
							</li>
							<li class="mt-2 d-flex align-items-center" style="line-height: 1.3;font-size:1.35rem;">
								<fa-icon
									[icon]="faCheck"
									[fixedWidth]="true"
									class="bg-secondary text-white mr-3 rounded-circle"
								></fa-icon>
								<span>
									No worries, no commitment, no spam<br />
									<b class="text-primary" style="font-size:1.75rem"> 100% free! </b>
								</span>
							</li>
						</ul>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,

	styles: [
		`
			:host ::ng-deep .modal-inner > .container {
				max-width: 95vw;
			}
			:host ::ng-deep .close-btn {
				color: #000;
			}
		`,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginModalComponent implements OnChanges {
	@Input() show!: boolean;
	@Input() state = LoginState.Login;
	@Output() loggedIn = new EventEmitter<void>();
	@Output() showChange = new EventEmitter<boolean>();
	LoginState = LoginState;
	faCheck = faCheck;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.state) {
			this.state = changes.state.currentValue;
		}
	}
}

enum LoginState {
	Login,
	Register,
	ForgotPass,
}
