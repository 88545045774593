import { Component, ChangeDetectionStrategy, Input, OnInit } from "@angular/core";
import { RestService, Service } from "@core/app/rest.service";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { IHomeTile } from "@model/home-tile";
import { TransferRxService } from "@common/services/transfer-rx.service";
import { ListingService } from "@core/app/listing/services/listing.service";
import { HttpClient } from "@angular/common/http";

@Component({
	selector: "cm-stevo-listing-nearby-homes",
	template: `
		<ng-container *ngIf="cards$ | async as cards">
			<ng-container *ngIf="cards.length > 0">
				<h3 class="my-3 tk-adelle font-weight-bold text-center">{{ title }}</h3>
				<cm-slider [slidesInView]="slidesInView" itemscope itemtype="http://schema.org/OfferCatalog">
					<ng-template cmSlide *ngFor="let card of cards">
						<cm-stevo-listing-card
							[data]="card"
							class="p-1"
							itemprop="itemListElement"
							itemscope
							itemtype="http://schema.org/Offer"
							(requestInfo)="listingInfo = $event; showModal = true"
							(requestTour)="listingInfo = $event; showTourModal = true"
						></cm-stevo-listing-card>
					</ng-template>
				</cm-slider>
			</ng-container>
		</ng-container>
		<cm-modal [show]="showModal" [detach]="true" (showChange)="showModal = $event">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h3 col-12 text-white font-normal font-weight-normal text-uppercase flex-center p-3 mb-0 bg-secondary"
					>
						Request Info
					</div>
					<cm-form
						class="text-center col-12"
						[formid]="71"
						[prefill]="{ listingid: listingInfo.listingid }"
						(onSubmit)="showModal = false"
						[gtmEvent]="'Request Info'"
						[gtmEventLabel]="listingInfo.content_page_url"
						[submitClass]="'btn btn-primary text-white font-weight-bold m-auto rounded'"
					></cm-form>
				</div>
			</ng-template>
		</cm-modal>
		<cm-modal [show]="showTourModal" [detach]="true" (showChange)="showTourModal = $event">
			<ng-template cmModal>
				<div class="row">
					<div
						class="h3 col-12 text-white font-normal font-weight-normal text-uppercase flex-center p-3 mb-0 bg-secondary"
					>
						Request A Tour
					</div>
					<cm-form
						class="text-center col-12"
						[formid]="71"
						[prefill]="{ listingid: listingInfo.listingid }"
						(onSubmit)="showTourModal = false"
						[gtmEvent]="'Request A Tour'"
						[gtmEventLabel]="listingInfo.content_page_url"
						[submitClass]="'btn btn-primary text-white font-weight-bold m-auto rounded'"
					></cm-form>
				</div>
			</ng-template>
		</cm-modal>
	`,
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StevoListingNearbyHomesComponent implements OnInit {
	@Input() title: string = "Similar Homes In This Area";
	@Input() sold: boolean = false;
	@Input() pending: boolean = false;
	@Input() salespersonid?: string | number;
	@Input() notListingid?: string | number;
	@Input() limit: string | number = 12;
	@Input() zipCode?: string | number;
	@Input() listingids?: string[] | number[];
	@Input() slidesInView?: string | number | { [key: string]: string | number } = {
		xs: 1,
		768: 2,
		1100: 3,
		1500: 4,
		1900: 5,
		2200: 6,
	};

	listingInfo: any = {};
	showTourModal: boolean = false;
	showModal: boolean = false;
	cards$: Observable<IHomeTile[]> = of([]);

	constructor(
		private http: HttpClient,
		private listingService: ListingService,
		private transfer: TransferRxService,
	) {}

	ngOnInit() {
		const vars = {
			postal_code: this.zipCode,
			limit: this.limit,
			sold: this.sold ? true : undefined,
			pending_sale: this.pending ? true : undefined,
			notListingid: this.notListingid,
			listingids: this.listingids ? this.listingids : undefined,
			salespersonid: this.salespersonid,
		};
		this.cards$ = this.transfer
			.transfer$("GetHomes:" + JSON.stringify(vars), () =>
				this.http.post("/api/statement/GetHomes", {
					vars,
				}),
			)
			.pipe(
				map((response: any) => response.results.map((row: any) => this.listingService.makeHomeTileObject(row))),
			);
	}
}
