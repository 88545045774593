import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { IStmtResults } from "@model/stmt-results";
import { IGetUserPermissions } from "@model/stmt/GetUserPermissions";
import { ToastrService } from "ngx-toastr";
import { combineLatest, Observable, of, Subject } from "rxjs";
import { concat, distinctUntilChanged, filter, map, shareReplay, switchMap, tap } from "rxjs/operators";
import { RestHelperService } from "./rest-helper.service";
import { RestService, Service } from "./rest.service";

@Injectable({ providedIn: "root" })
export class UserService {
	user: any = null;
	userService: Service;
	authService: Service;
	refreshLock = false;

	private userBS = new Subject<any>();

	user$ = this.userBS.pipe(shareReplay(1));
	userid$ = this.user$.pipe(map((user) => (user ? user.userid : null)));
	empid$: Observable<string | null> = this.user$.pipe(map((user) => (user ? user.empid : null)));
	driverid$ = this.user$.pipe(map((user) => (user ? user.driverid : null)));
	customerid$ = this.user$.pipe(map((user) => (user ? user.customerId : null)));
	salespersonid$ = this.user$.pipe(map((user) => (user ? user.salespersonId : null)));

	permissions$ = this.userid$.pipe(
		switchMap((userid) => {
			if (userid) {
				return of(null).pipe(
					concat(
						this.http
							.post("/api/statement/GetUserPermissions", { vars: { userid } })
							.pipe(map((response) => (response as IStmtResults<IGetUserPermissions>).results!)),
					),
				);
			} else {
				return of([]);
			}
		}),
		map((results) => results && Permissions.fromDb(results)),
		shareReplay(1),
		filter((results) => !!results),
	) as Observable<Permissions>;

	isAdmin$ = this.user$.pipe(
		map((user) => user && user.auth_role === "Administrator"),
		distinctUntilChanged(),
	);
	isCustomer$ = this.user$.pipe(
		map((user) => (user ? user.customerId || !user.auth_role : false)),
		distinctUntilChanged(),
	);
	isDataEntry$ = this.user$.pipe(
		map((user) => (user ? user.auth_role === "Data Entry" : false)),
		distinctUntilChanged(),
	);
	isDriver$ = this.user$.pipe(
		map((user) => (user ? user.auth_role === "Transport Driver" : false)),
		distinctUntilChanged(),
	);
	isDriverManager$ = this.user$.pipe(
		map((user) => (user ? user.auth_role === "Transport Manager" : false)),
		distinctUntilChanged(),
	);
	isEmp$ = this.user$.pipe(
		map((user) => (user ? user.empid !== null : false)),
		distinctUntilChanged(),
	);
	isFinance$ = this.user$.pipe(
		map((user) => (user ? user.auth_role === "Finance" : false)),
		distinctUntilChanged(),
	);
	isManager$ = this.user$.pipe(
		map((user) => (user ? user.auth_role === "Manager" : false)),
		distinctUntilChanged(),
	);
	isPublic$ = this.user$.pipe(
		map((user) => (user ? user.auth_role === "Public" : false)),
		distinctUntilChanged(),
	);
	isSalesPerson$ = this.user$.pipe(
		map((user) =>
			user
				? user.salespersonId ||
				  user.auth_role === "Salesperson" ||
				  user.auth_role === "Salesperson Teledrip" ||
				  user.auth_role === "Salesperson Share"
				: false,
		),
		distinctUntilChanged(),
	);
	isSimpleDataEntry$ = this.user$.pipe(
		map((user) => (user ? user.auth_role === "Simple Data Entry" : false)),
		distinctUntilChanged(),
	);
	isSocial$ = this.user$.pipe(
		map((user) => (user ? user.auth_role === "Social Media" : false)),
		distinctUntilChanged(),
	);
	isTeledrip$ = this.user$.pipe(
		map((user) => (user ? user.auth_role === "Salesperson Teledrip" : false)),
		distinctUntilChanged(),
	);
	isVisitor$ = combineLatest(
		this.isAdmin$,
		this.isFinance$,
		this.isManager$,
		this.isSalesPerson$,
		this.isSocial$,
		this.isTeledrip$,
		this.isDataEntry$,
		this.isSimpleDataEntry$,
	).pipe(
		map(([isAdmin, isFinance, isManager, isSalesPerson, isSocial, isTeledrip, isDataEntry, isSimpleDataEntry]) => {
			return (
				!isAdmin &&
				!isFinance &&
				!isManager &&
				!isSalesPerson &&
				!isSocial &&
				!isTeledrip &&
				!isDataEntry &&
				!isSimpleDataEntry
			);
		}),
		distinctUntilChanged(),
	);
	loggedIn$ = this.user$.pipe(map((user) => !!user));

	/**
	 * User's first name, or "Guest" if the user is not logged in.
	 */
	name$ = this.user$.pipe(
		map((user) => (user ? user.first_name : "Guest")),
		distinctUntilChanged(),
	);
	img$ = this.user$.pipe(map((user) => (user ? user.img : null)));
	firstName$ = this.user$.pipe(
		map((user) => (user ? user.first_name : null)),
		distinctUntilChanged(),
	);
	lastName$ = this.user$.pipe(
		map((user) => (user ? user.last_name : null)),
		distinctUntilChanged(),
	);
	fullName$ = this.user$.pipe(
		map((user) => (user ? user.first_name + " " + user.last_name : null)),
		distinctUntilChanged(),
	);
	authRole$ = this.user$.pipe(
		map((user) => (user && user.auth_role ? user.auth_role : null)),
		distinctUntilChanged(),
	);
	fav$ = this.user$.pipe(
		map((user) => (user && user.favs ? user.favs : [])),
		distinctUntilChanged(),
	);
	address$ = this.user$.pipe(
		map((user) => (user ? user.address : null)),
		distinctUntilChanged(),
	);
	phoneNumber$ = this.user$.pipe(
		map((user) => (user ? user.phoneNumber : null)),
		distinctUntilChanged(),
	);
	email$ = this.user$.pipe(
		map((user) => (user ? user.email : null)),
		distinctUntilChanged(),
	);
	twillioNumber$ = this.user$.pipe(
		map((user) => (user ? user.twillioNumber : null)),
		distinctUntilChanged(),
	);

	constructor(
		public RestHelper: RestHelperService,
		private http: HttpClient,
		private toastrService: ToastrService,
		restService: RestService,
		private router: Router,
	) {
		this.userService = restService.init("user");
		this.authService = restService.init("auth");
		this.refresh();
	}

	hasPermission$(path: string[]): Observable<boolean> {
		return this.permissions$.pipe(map((perms) => perms.hasPermission(path)));
	}

	/**
	 * Instantiates the User
	 *
	 * @param userService
	 * @param tokenResponse
	 */
	logIn(username: string, password: string, remember: boolean): Observable<void> {
		return this.authService
			.post$("login", { user_login: username, password, remember: remember || undefined })
			.pipe(
				tap((res: any) => this.setUser(res.user)),
				shareReplay(1),
			);
	}

	logOut(): Observable<void> {
		const ret = this.authService.post$("logout").pipe(shareReplay(1));
		ret.subscribe(() => {
			this.setUser(null);
		});

		return ret;
	}

	useUid(uid: string) {
		return this.userService.post("uid", { uid: uid }).then((userResponse: any) => {
			if (userResponse.success) {
				this.setUser(userResponse.user);
				return userResponse;
			} else {
				this.toastrService.error(userResponse.message);
				this.router.navigate(["/"]);
			}
		});
	}

	/**
	 * Returns the user
	 *
	 * @param prop
	 *
	 * @returns {*}
	 */
	get(prop?: any) {
		if (!this.user) {
			return null;
		}

		if (prop) {
			if (this.user.hasOwnProperty(prop)) {
				return this.user[prop];
			} else {
				return null;
			}
		}

		return this.user;
	}

	/**
	 * Returns the User's name
	 *
	 * @returns {string}
	 */
	getName() {
		return this.user ? this.user.first_name : "";
	}

	/**
	 * Returns whether the user is an admin or not
	 */
	isAdmin() {
		return this.user ? this.user.auth_role === "Administrator" : false;
	}

	isCustomer() {
		return this.user ? this.user.customerId || !this.user.auth_role : false;
	}

	isEmp() {
		return this.user ? !!this.user.empid : false;
	}

	isPublic() {
		return this.user ? this.user.auth_role === "Public" : false;
	}

	isFinance() {
		return this.user ? this.user.auth_role === "Finance" : false;
	}

	isManager() {
		return this.user ? this.user.auth_role === "Manager" : false;
	}

	isDataEntry() {
		return this.user ? this.user.auth_role === "Data Entry" : false;
	}

	isSocial() {
		return this.user ? this.user.auth_role === "Social Media" : false;
	}

	isSalesPerson() {
		return this.user
			? this.user.salespersonId ||
					this.user.auth_role === "Salesperson" ||
					this.user.auth_role === "Salesperson Teledrip" ||
					this.user.auth_role === "Salesperson Share"
			: false;
	}

	loggedIn() {
		return !!this.user;
	}

	/**
	 * Refresh the user info from server
	 * @param userService
	 */
	async refresh() {
		if (!this.refreshLock) {
			this.refreshLock = true;
			await this.userService.one("current").then((res: any) => this.setUser(res.user));
			this.refreshLock = false;
		}
	}

	setUser(user: any) {
		this.user = user;
		this.userBS.next(user);
	}
}

export class Permissions {
	constructor(private tree: IRecursiveMap<string>, private nodeIds: Set<string>) {}

	static fromDb(dbNodes: IGetUserPermissions[]): Permissions {
		// flat version of tree
		const nodes = new Map<string, IRecursiveMap<string>>();
		// nested tree that will be returned
		const tree = new Map<string, IRecursiveMap<string>>();

		for (const row of dbNodes) {
			const newmap = new Map();
			if (row.auth_node_ofid) {
				const parent = nodes.get(row.auth_node_ofid);
				if (parent) {
					parent.set(row.auth_node, newmap);
				}
			} else {
				tree.set(row.auth_node, newmap);
			}
			nodes.set(row.auth_nodeid, newmap);
		}

		return new Permissions(tree, new Set(nodes.keys()));
	}

	hasNodeId(id: string) {
		return this.nodeIds.has(id) || this.tree.has("*");
	}

	hasPermission(path: string[]): boolean {
		if (this.tree.has("*")) {
			return true;
		}

		let cur: IRecursiveMap<string> | undefined = this.tree;
		for (const segment of path) {
			cur = cur.get(segment);
			if (!cur) {
				return false;
			}
		}
		return true;
	}
}

interface IRecursiveMap<K> extends Map<K, IRecursiveMap<K>> {}
