import { IEnvironment } from "./environment-types";
import { environment as environment26 } from "./environment.26";
import { environmentDefault } from "./environment.default";
export { IEnvironment } from "./environment-types";

export const environment: IEnvironment = {
	...environmentDefault,
	...environment26,
	production: true,
};
