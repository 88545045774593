import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { faCaretDown, faCaretUp, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { map } from "rxjs/operators";
import { CompareService } from "../../services/compare.service";

let nextId = 0;

@Component({
	selector: "cm-stevo-compare-popup",
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div *ngIf="(compare.compare$ | async).size" class="d-none d-lg-flex flex-column align-items-end">
			<button
				type="button"
				class="btn btn-secondary text-white font-weight-bold text-uppercase bg-secondary"
				(click)="collapse = !collapse"
			>
				Compare Tool
				<span class="bg-danger text-center text-white rounded-circle px-2 py-1 ml-4 mr-1">{{
					(compare.compare$ | async).size
				}}</span>
				<fa-icon *ngIf="collapse" [icon]="faCaretUp"></fa-icon>
				<fa-icon *ngIf="!collapse" [icon]="faCaretDown"></fa-icon>
			</button>
			<div *ngIf="!collapse" class="bg-white shadow border p-3">
				<div class="d-flex ml-n3 row">
					<div
						*ngFor="let listing of compare.listings$ | async"
						class="col-12 listing text-center position-relative pl-3"
					>
						<div class="p-2">
							<div class="d-flex flex-wrap py-1 text-primary text-center">
								<div class="d-flex flex-wrap py-2 text-primary align-items-center flex-grow-1">
									<!-- <div class="col-12"> -->
									<div class="col-4">
										<div class="btn-close m-1">
											<div class="embed-responsive embed-responsive-1by1">
												<fa-icon
													[icon]="faTimes"
													role="button"
													class="embed-responsive-item flex-center bg-white rounded-circle"
													(click)="compare.removeListing(listing.listingid)"
												></fa-icon>
											</div>
										</div>
										<div class="embed-responsive embed-responsive-16by9">
											<div class="embed-responsive-item d-flex align-items-center">
												<img
													*ngIf="listing.img_file"
													class="img-fluid"
													[src]="listing.img_dir + listing.img_file | image: 'm'"
												/>
											</div>
										</div>
									</div>
									<div class="col-8">
										<div class="col-12 col-sm-8 px-0 my-2 text-center text-sm-left">
											{{ listing.address1 }}, {{ listing.city }}, {{ listing.abbrev }}
										</div>

										<div class="row">
											<div class="col-6 col-sm-3 border-right my-2">
												<b class="h4">{{ listing.beds_total }}</b
												><br />
												Beds
											</div>
											<div class="col-6 col-sm-3 border-sm-right my-2">
												<b class="h4">{{ listing.baths_total }}</b
												><br />
												Baths
											</div>
											<div class="col-6 col-sm-3 border-right my-2">
												<b class="h4">{{ listing.square_feet | number: "1.0-0" }}</b
												><br />
												Sq Ft
											</div>
											<div class="col-6 col-sm-3 my-2">
												<b class="h4">{{ listing.lot_acres | number: "1.2-2" }}</b
												><br />
												Acres
											</div>
										</div>
										<div class="col-12 px-0s text-right my-2" style="font-size:1.35rem;">
											{{ listing.msrp | currency: undefined:"symbol":"1.0-0" }}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div *ngIf="(compare.compare$ | async).size > 0" class="col-12 text-center mt-3">
						<span
							(click)="compare.clear()"
							class="btn btn-secondary text-white font-weight-bold mx-2 text-uppercase rounded"
						>
							Clear
						</span>
						<a
							routerLink="/compare"
							[queryParams]="compareQuery$ | async"
							class="btn btn-primary text-white font-weight-bold mx-2 text-uppercase rounded"
							(click)="collapse = true"
						>
							View Full Comparison
						</a>
					</div>
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			:host {
				position: fixed;
				right: 3rem;
				bottom: 0;
				z-index: 1032;
				max-width: 600px;
			}

			.btn-close {
				position: absolute;
				top: 0;
				/* right: 0; */
				font-size: 1.5rem;
				width: 2rem;
				height: 2rem;
				cursor: pointer;
				z-index: 2;
			}

			.listing {
				width: 250px;
			}
			.embed-responsive img {
				max-height: 100%;
				object-fit: cover;
				width: 100%;
			}
		`,
	],
})
export class ComparePopupComponent {
	@Input() listingid!: number;

	faCaretUp = faCaretUp;
	faCaretDown = faCaretDown;
	faTimes = faTimes;

	collapse = true;
	id = nextId++;

	compareQuery$ = this.compare.compare$.pipe(map((compare) => ({ listingids: [...compare].join(",") })));

	constructor(public compare: CompareService) {}

	check(checked: boolean) {
		if (checked) {
			this.compare.addListing(this.listingid);
		} else {
			this.compare.removeListing(this.listingid);
		}
	}
}
