import { HttpClient } from "@angular/common/http";
import { Component, Inject } from "@angular/core";
import { GTMService } from "@core/app/gtm.service";
import { IPageData } from "@model/page-data";
import { ToastrService } from "ngx-toastr";
import { first, tap } from "rxjs/operators";

@Component({
	selector: "cm-footer-stevo",
	template: `
		<div class="stevo-footer container-fluid text-white py-3 px-5" itemscope itemtype="http://schema.org/WPFooter">
			<meta itemprop="copyrightYear" [content]="year" />
			<meta itemprop="copyrightHolder" [content]="siteName" />
			<div class="row">
				<div class="col-12 col-lg-3">
					<div class="text-center text-lg-left">
						<img
							loading="lazy"
							src="//dms.rvimg.com/s3static/26/jess-logo.png"
							alt="Jess Garrison Homes"
							class="img-fluid logo-img"
							style="width: 400px"
						/>
					</div>

					<div class="py-2">
						<div
							*ngFor="let l of locations"
							class="my-2 contact-info text-center text-lg-left"
							style="font-size: 1.25rem"
						>
							<a
								(click)="linkClicked('Link Click', 'Phone Call', 'click')"
								href="tel:{{ l.org_phone_number }}"
								class="text-white"
								>{{ l.org_phone_number }}</a
							>
							<br />
							<a
								(click)="linkClicked('Link Click', 'Email', 'click')"
								href="mailto:{{ l.email_address }}"
								class="text-white"
								>{{ l.email_address }}</a
							>
							<br />
							<cm-social-links2></cm-social-links2>
						</div>
					</div>
				</div>
				<div class="col-12 col-lg-5 my-lg-0 d-flex flex-column text-center align-items-center">
					<a href="//jessgarrisonhomes.com/" target="_blank">
						<img
							src="//dms.rvimg.com/s3static/12/cb-monohorizontal-woodland-3.png"
							alt="Coldwell"
							class="img-fluid my-3"
							style="width: 300px"
						/>
					</a>
					<img
						loading="lazy"
						src="//dms.rvimg.com/s3static/22/realty-icons-t.png"
						alt="Realty Icons"
						class="img-fluid my-2"
					/>
				</div>
				<div class="col-12 col-lg-4 text-center text-lg-right">
					<button
						type="button"
						class="btn btn-outline-white newsletter text-uppercase font-italic font-weight-bold rounded"
						style="font-size: 1.25rem; line-height: 1; max-width: 250px"
						(click)="showModal = true"
					>
						Sign Up For My Newsletter!
					</button>
					<div class="mt-3 ">
						Powered by
						<a class="text-white" href="//designforcemarketing.com/">Design Force Marketing</a>
					</div>
					<div class="my-1">&copy; Copyright {{ year }}. All Rights Reserved.</div>
					<a routerLink="/terms-of-use" class="text-white">Terms of Use</a> |
					<a routerLink="/privacy-policy" class="text-white">Privacy Policy</a>
				</div>
			</div>
		</div>

		<cm-modal [show]="showModal" (showChange)="showModal = $event">
			<ng-template cmModal>
				<div class="row">
					<div class="col-12 py-3 text-center h5-sm h2 my-0 text-white bg-secondary">NEWSLETTER SIGN UP</div>
					<div class="col-12 d-flex">
						<div class="input-group my-3 px-2 align-items-center">
							<input
								class="form-control"
								type="text"
								name="search"
								placeholder="Enter email address here..."
								[(ngModel)]="email"
							/>
							<div class="input-group-append">
								<div class="btn btn-primary text-white text-uppercase" style="font-weight:700;">
									<span class="text" (click)="sendEmail()">Send</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</ng-template>
		</cm-modal>
	`,
	styleUrls: ["./footer-stevo.component.scss"],
})
export class FooterStevoComponent {
	year = new Date().getFullYear();
	siteName = this.pageData.headerData.queries.GetDealerInfo[0].dealer;
	locations = this.pageData.locations;
	email: string = "";
	showModal = false;
	constructor(
		@Inject("PAGE_DATA") private pageData: IPageData,
		private http: HttpClient,
		private toastrService: ToastrService,
		private gtmService: GTMService,
	) {}
	sendEmail() {
		if (this.email.length > 0 && this.validateEmail(this.email)) {
			this.http
				.post("/api/realty/addToList", { email: this.email })
				.pipe(
					tap((response: any) => {
						if (response.success) {
							this.toastrService.success("Email Signed Up");
							this.email = "";
							this.showModal = false;
							this.gtmService.track("Newsletter Signup", "success", "submit");
						} else {
							this.toastrService.error("Invalid email submitted");
						}
					}),
					first(),
				)
				.subscribe();
		} else {
			this.toastrService.error("Invalid email submitted");
		}
	}

	validateEmail(email: string) {
		if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
			return true;
		}
		return false;
	}

	linkClicked(category: string, label: string, action: string) {
		this.gtmService.track(category, action, label);
	}
}
