import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxChartsModule } from "@swimlane/ngx-charts";
import { ModalModule } from "shared";
import { AthomeModule } from "../athome/athome.module";
import { FormModule } from "../form/form.module";
import { ImageModule } from "../image/image.module";
import { InventoryModule } from "../inventory/inventory.module";
import { LayoutModule } from "../layout/layout.module";
import { Map2Module } from "../map2/map2.module";
import { Pagination2Module } from "../pagination2/pagination2.module";
import { SliderModule } from "../slider/slider.module";
import { StevoAccountInfoComponent } from "./components/account/account-info.component";
import { StevoSavedSearchesComponent } from "./components/account/saved-searches.component";
import { StevoWatchedPropertiesComponent } from "./components/account/watched-properties.component";
import { StevoBlogPostComponent } from "./components/blog/post";
import { StevoBlogWrapperComponent } from "./components/blog/wrapper.component";
import { StevoCitySchoolsComponent } from "./components/city/schools.component";
import { StevoCityWrapperComponent } from "./components/city/wrapper.component";
import { StevoCommunitySolutionComponent } from "./components/community/solution.component";
import { CompareButtonComponent } from "./components/compare/compare-button.component";
import { ProertyComparisonComponent } from "./components/compare/property-comparison.component";
import { StevoContactAshleyComponent } from "./components/contact/ashley.component";
import { StevoContactAtheleteToRealtorComponent } from "./components/contact/athelete-to-realtor.component";
import { StevoContactCommunityComponent } from "./components/contact/community.component";
import { StevoContactHeaderComponent } from "./components/contact/header.component";
import { StevoContactMapComponent } from "./components/contact/map.component";
import { StevoListingCalcComponent } from "./components/detail/calc.component";
import { StevoListingWrapperComponent } from "./components/detail/listing-wrapper.component";
import { FavoritesModule } from "./components/favorites/favorites.module";
import { StevoHomeBlogComponent } from "./components/homepage/blog.component";
import { StevoHomeBrowsebyComponent } from "./components/homepage/browseby.component";
import { StevoHomeCommunityComponent } from "./components/homepage/community.component";
import { StevoExploreComponent } from "./components/homepage/explore.component";
import { StevoInventorySoldComponent } from "./components/homepage/inventory-sold.component";
import { StevoHomeInventoryComponent } from "./components/homepage/inventory.component";
import { StevoHomeLmcuComponent } from "./components/homepage/lmcu.component";
import { StevoHomeMapComponent } from "./components/homepage/map.component";
import { StevoHomeReviewsComponent } from "./components/homepage/reviews.component";
import { StevoHomeVideosComponent } from "./components/homepage/videos.component";
import { StevoListingVideoModalComponent } from "./components/listing-video-modal.component";
import { StevoListingAgentComponent } from "./components/listing/agent.component";
import { StevoListingCardComponent } from "./components/listing/card.component";
import { StevoFilterComponent } from "./components/listing/filter/filter.component";
import { StevoFilterHeaderComponent } from "./components/listing/filter/filter-header.component";
import { StevoListingMapComponent } from "./components/listing/map.component";
import { StevoListingSlideshowComponent } from "./components/listing/slideshow.component";
import { StevoListingsBlogComponent } from "./components/listings/blog.component";
import { StevoListingsBrowsebyComponent } from "./components/listings/browseby.component";
import { StevoListingsDreamHomeComponent } from "./components/listings/dream-homes.component";
import { StevoInventorySectionComponent } from "./components/listings/inventory-section.component";
import { StevoListingNearbyHomesComponent } from "./components/listings/nearby-homes.component";
import { SendAMessageModalComponent } from "./components/modals/send-a-message-modal.component";
import { StevoSellAHomeBlogComponent } from "./components/sell-a-home/blog.component";
import { StevoSellAHomeHappyClientsComponent } from "./components/sell-a-home/happy-clients.component";
import { StevoSellAHomeHeaderComponent } from "./components/sell-a-home/header.component";
import { StevoSoldHomesGalleryComponent } from "./components/sold-homes/gallery.component";
import { StevoPropertyTypeWrapperComponent } from "./components/property-type/wrapper.component";

@NgModule({
	declarations: [
		StevoHomeCommunityComponent,
		StevoHomeLmcuComponent,
		StevoHomeMapComponent,
		StevoHomeReviewsComponent,
		StevoHomeBlogComponent,
		StevoHomeBrowsebyComponent,
		StevoContactHeaderComponent,
		StevoContactCommunityComponent,
		StevoHomeInventoryComponent,
		StevoListingCardComponent,
		StevoFilterComponent,
		StevoContactMapComponent,
		StevoContactAtheleteToRealtorComponent,
		StevoSellAHomeHeaderComponent,
		StevoSellAHomeBlogComponent,
		StevoSellAHomeHappyClientsComponent,
		StevoInventorySectionComponent,
		StevoListingWrapperComponent,
		StevoListingSlideshowComponent,
		StevoListingMapComponent,
		StevoListingAgentComponent,
		StevoListingNearbyHomesComponent,
		StevoListingCalcComponent,
		StevoListingsBlogComponent,
		StevoListingsBrowsebyComponent,
		StevoListingsDreamHomeComponent,
		StevoBlogWrapperComponent,
		StevoCityWrapperComponent,
		StevoCitySchoolsComponent,
		CompareButtonComponent,
		StevoBlogPostComponent,
		StevoAccountInfoComponent,
		StevoWatchedPropertiesComponent,
		ProertyComparisonComponent,
		StevoSavedSearchesComponent,
		StevoExploreComponent,
		StevoCommunitySolutionComponent,
		StevoContactAshleyComponent,
		StevoSoldHomesGalleryComponent,
		SendAMessageModalComponent,
		StevoInventorySoldComponent,
		StevoHomeVideosComponent,
		StevoListingVideoModalComponent,
		StevoFilterHeaderComponent,
		StevoPropertyTypeWrapperComponent,
	],
	imports: [
		CommonModule,
		FontAwesomeModule,
		LayoutModule,
		FormModule,
		Map2Module,
		SliderModule,
		NgxChartsModule,
		FavoritesModule,
		ModalModule,
		InventoryModule,
		NgbModule,
		ImageModule,
		RouterModule,
		Pagination2Module,
		FormsModule,
	],
	exports: [
		StevoHomeCommunityComponent,
		StevoHomeLmcuComponent,
		StevoHomeMapComponent,
		StevoHomeReviewsComponent,
		StevoHomeBlogComponent,
		StevoHomeBrowsebyComponent,
		StevoContactHeaderComponent,
		StevoContactCommunityComponent,
		StevoHomeInventoryComponent,
		StevoListingCardComponent,
		StevoFilterComponent,
		StevoContactMapComponent,
		StevoContactAtheleteToRealtorComponent,
		StevoSellAHomeHeaderComponent,
		StevoSellAHomeBlogComponent,
		StevoSellAHomeHappyClientsComponent,
		StevoInventorySectionComponent,
		StevoListingWrapperComponent,
		StevoListingSlideshowComponent,
		StevoListingMapComponent,
		StevoListingAgentComponent,
		StevoListingNearbyHomesComponent,
		StevoListingsBlogComponent,
		StevoListingsBrowsebyComponent,
		StevoListingsDreamHomeComponent,
		StevoBlogWrapperComponent,
		StevoCityWrapperComponent,
		StevoCitySchoolsComponent,
		CompareButtonComponent,
		StevoBlogPostComponent,
		StevoAccountInfoComponent,
		StevoWatchedPropertiesComponent,
		ProertyComparisonComponent,
		StevoSavedSearchesComponent,
		StevoExploreComponent,
		StevoCommunitySolutionComponent,
		StevoContactAshleyComponent,
		StevoSoldHomesGalleryComponent,
		SendAMessageModalComponent,
		StevoInventorySoldComponent,
		StevoHomeVideosComponent,
		StevoListingVideoModalComponent,
		StevoFilterHeaderComponent,
		StevoPropertyTypeWrapperComponent,
	],
})
export class StevoModule {}
